@import "guidlineColor";
@import "guidline";
@import "guidlineMedia";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  // font-family: $TypeFont_manrope;
  background-color: #fafafa !important;
  padding-top: 88px;
  top: 0 !important;

  &.comingSoonPage {
    padding-top: 0;
    overflow: hidden;
  }

  &.modal-open {
    padding-right: 0 !important;
  }

  &.flight_info_open {
    overflow: hidden;

    .trackFlight_infoPanel {
      top: auto !important;
      bottom: 0 !important;
      overflow-y: auto !important;

      .mobile_arrow {
        span {
          img {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
          }
        }
      }
    }

    .overlay_block {
      display: block !important;
    }
  }

  @include ipad_view {
    padding-top: 76px;
  }

  @include tab_view {
    padding-top: 56px;
  }

  @include mobile_view {
    padding-top: 50px;
  }
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

p {
  margin: 0;
}

.redStar {
  color: color(red_dark);
}

input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.padtb {
  padding: 100px 0;

  @include ipad_view {
    padding: 70px 0;
  }

  @include tab_view {
    padding: 50px 0;
  }

  @include mobile_view {
    padding: 40px 0;
  }
}

.padtop {
  padding-top: 100px;

  @include ipad_view {
    padding-top: 70px;
  }

  @include tab_view {
    padding-top: 50px;
  }

  @include mobile_view {
    padding-top: 40px;
  }
}

.padbtm {
  padding-bottom: 100px;

  @include ipad_view {
    padding-bottom: 70px;
  }

  @include tab_view {
    padding-bottom: 50px;
  }

  @include mobile_view {
    padding-bottom: 40px 0;
  }
}

//  Start Header Section CSS
.wraper {
  position: relative;
  z-index: 9;

  .main_header {
    width: 100%;
    background: color(header_bg);
    padding: 15px 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    > .container {
      @include ipad_view {
        max-width: 100%;
        padding: 0 15px;
      }
    }

    @include ipad_view {
      padding: 15px 0;
    }

    @include tab_view {
      padding: 10px 0;
    }

    .navbar {
      padding: 0;

      > .container-fluid {
        @include ipad_view {
          padding: 0;
        }
      }

      .push-left {
        margin-left: auto;

        @include ipad_view {
          display: flex;
          order: -1;
          margin: 0;
          width: 150px;
          display: none;
        }

        @include tab_view {
          width: 100px;
        }

        .hamburger {
          background: transparent;
          border: none;
          cursor: pointer;
          display: none;
          outline: none;
          height: 24px;
          position: relative;
          width: 24px;
          z-index: 1000;

          @include ipad_view {
            display: inline-block;
          }

          .hamburger-line {
            background: color(header_text);
            height: 2px;
            position: absolute;
            left: 0;
            transition: all 0.2s ease-out;
            width: 100%;

            &.hamburger-line-top {
              top: 3px;
            }

            &.hamburger-line-middle {
              top: 50%;
              transform: translatey(-50%);
            }

            &.hamburger-line-bottom {
              bottom: 3px;
            }
          }
        }

        .nav-menu {
          list-style: none;
          transition: all 0.4s linear;

          @include ipad_view {
            width: 220px;
            height: 100%;
            overflow-y: auto;
            background: color(header_bg);
            flex-direction: column;
            justify-content: flex-start;
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: -220px;
            padding: 76px 0 15px;
            z-index: 9;

            @include tab_view {
              padding-top: 56px;
            }

            @include mobile_view {
              padding-top: 50px;
            }

            &:before {
              content: "";
              background-color: color(border_color);
              height: 1px;
              width: 100%;
              display: block;
              margin: 0 0 10px;
            }
          }

          li {
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include ipad-view {
              padding: 6px 20px;
              justify-content: flex-start;
            }

            @include tab_view {
              padding: 4px 20px;
            }

            a {
              @include medium_text;
              @include font_600;
              color: color(header_text);
              // font-family: $TypeFont_raleway;
              text-decoration: none;
              position: relative;
              display: inline-block;

              &.nav__link {
                &.is_active {
                  color: color(primary_theme);

                  &::after {
                    background: color(primary_theme);
                    transform: scale(1, 1);
                    -webkit-transform: scale(1, 1);
                    -moz-transform: scale(1, 1);
                  }
                }
              }

              @include ipad_view {
                @include font_20;
                line-height: normal;
                padding: 2px 0;
              }

              @include tab_view {
                @include font_18;
              }

              &:after {
                content: "";
                position: absolute;
                top: auto;
                bottom: -2px;
                left: 0;
                right: 0;
                width: 100%;
                height: 2px;
                background: color(header_text);
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                transform: scale(0, 1);
                -webkit-transform: scale(0, 1);
                -moz-transform: scale(0, 1);
              }

              &:hover {
                &:after {
                  transform: scale(1, 1);
                  -webkit-transform: scale(1, 1);
                  -moz-transform: scale(1, 1);
                }
              }
            }

            select {
              background: transparent;
              border: 0;
              @include medium_text;
              @include font_500;
              color: color(header_text);
              text-transform: uppercase;

              option {
                @include detail_text;
                @include font_700;
                color: color(header_text);
              }
            }
          }
        }
      }

      .mobile_subBtn {
        display: none;
        color: color(header_text);
        background-color: transparent;

        &.is_active {
          position: relative;

          &::after {
            width: 65%;
            height: 2px;
            border-radius: 6px;
            background: color(header_text);
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            content: "";
          }
        }

        @include ipad_view {
          display: block;
          @include font_14;
          padding: 0;
          box-shadow: none;
        }
      }

      .language_dropdown {
        margin: 0 0 0 10px;

        @include ipad_view {
          display: none;
        }

        @include ipad_view {
          margin: 0;
        }

        &.home_languageDropdown {
          #google_translate_element {
            select {
              background: url(https://d1kaer0po85hkk.cloudfront.net/assets/caretdownicblack.svg);
              background-repeat: no-repeat;
              background-position: top calc(50% + 1px) right 6px;
              background-size: 18px;

              @include tab_view {
                background-size: 14px;
                background-position: top 50% right 6px;
              }
            }
          }
        }

        #google_translate_element {
          @include ipad_view {
            width: 150px;
            text-align: right;
          }

          @include tab_view {
            width: 100px;
            padding: 0 0 0 10px;
          }

          @media print {
            display: none;
          }

          .skiptranslate {
            font-size: 0;
            color: currentColor;

            > span {
              display: none !important;
            }
          }

          select {
            @include detail_text;
            line-height: normal;
            @include font_600;
            font-family: $TypeFont_raleway;
            text-transform: capitalize;
            width: 100%;
            min-width: 120px;
            padding: 8px 26px 8px 8px;
            margin: 0;
            outline: none;
            appearance: none;
            -webkit-appearance: none;
            background: url(https://d1kaer0po85hkk.cloudfront.net/assets/caretdownic.svg);
            background-repeat: no-repeat;
            background-position: top calc(50% + 1px) right 6px;
            background-size: 18px;
            cursor: pointer;
            border: 1px solid color(header_text);
            border-radius: 4px;
            color: color(header_text);
            line-height: normal;
            box-shadow: 0px 1px 8px 3px rgba(255, 255, 255, 0.25);
            text-align: left;

            @include ipad_view {
              width: auto;
              max-width: 100%;
              min-width: 1px;
              background-size: 14px;
              background-position: top 50% right 6px;
              line-height: normal;
            }

            @include tab_view {
              width: 100%;
              max-width: 100%;
              min-width: 1px;
              background-size: 14px;
              background-position: top 50% right 6px;
              line-height: normal;
            }

            @include mobile_view {
              @include font_12;
              padding: 6px 22px 6px 8px;
            }

            option {
              color: color(Contact_hed);
              background-color: rgba($primary_theme_opacity, $btn_opacity_7);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .logo {
      &.home_logo {
        a {
          cursor: default;
        }
      }

      a {
        @include medium_text;
        @include font_500;
        color: color(header_text);
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
        text-decoration: none;
      }

      img {
        max-height: 58px;
        width: auto;

        @include ipad_view {
          max-height: 46px;
        }

        @include tab_view {
          max-height: 36px;
        }

        @include mobile_view {
          max-height: 30px;
        }
      }
    }
  }
}

.overlay_block {
  display: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: color(primary_theme);
  opacity: 0.15;
}

.menu-active {
  @include ipad-view {
    overflow: hidden;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
  }

  .main_header {
    .navbar {
      .push-left {
        .nav-menu {
          @include ipad-view {
            left: 0;

            &:before {
              display: block;
            }
          }
        }

        .hamburger {
          .hamburger-line {
            &.hamburger-line-top {
              top: 50%;
              transform: rotate(45deg) translatey(-50%);
            }

            &.hamburger-line-middle {
              left: 50%;
              opacity: 0;
              width: 0;
            }

            &.hamburger-line-bottom {
              bottom: 50%;
              transform: rotate(-45deg) translatey(50%);
            }
          }
        }
      }
    }
  }

  .overlay_block {
    @include ipad-view {
      display: block !important;
    }
  }
}

//  Close Header Section CSS
//  Start Banner Section CSS
.banner {
  width: 100%;
  overflow: hidden;
  display: flex;
  background-image: url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.webp");
  background-image: -webkit-image-set(
    url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.webp")
  );
  background-image: image-set(
    url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.jpg")
  );
  background-size: cover;
  position: relative;

  @include mobile-view {
    padding: 50px 0 0 0;
  }

  &::before {
    content: "";
    position: absolute;
    height: 380px;
    width: 100%;
    background-image: url(https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_left.png);
    background-image: -webkit-image-set(
      url("https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_left.webp")
    );
    background-image: image-set(
      url("https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_left.png")
    );
    background-repeat: no-repeat;
    background-size: contain;
    top: -6%;
    left: 28%;

    @include desktop_max {
      max-width: 300px;
      top: -3%;
      left: 41%;
      z-index: 1;
    }

    @include laptop_max {
      height: 220px;
      width: 100%;
      max-width: 300px;
      top: -6%;
      left: 35%;
    }

    @include tab-max {
      height: 263px;
      top: -6%;
      left: 28%;
      z-index: 1;
      max-width: 285px;
    }

    @include ipad-view {
      height: 300px;
      top: -4%;
      left: 0;
      max-width: 275px;
    }

    @include tab-view {
      height: 125px;
      top: -6%;
      left: 37%;
    }

    @include mobile-view {
      top: -3%;
      left: -3%;
      height: 127px;
    }

    @include mobile-sm-view {
      top: -4%;
      left: -9%;
      height: 125px;
      max-width: 150px;
    }
  }

  .banner_cover {
    display: flex;
    position: relative;

    @include ipad-view {
      justify-content: space-between;
      flex-wrap: wrap-reverse;
    }

    @include mobile-view {
      flex-wrap: wrap-reverse;
    }

    .banner_text {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include desktop_max {
        width: 58%;
      }

      @include laptop_max {
        width: 50%;
      }

      @include tab-max {
        width: 50%;
      }

      @include tab-max {
        margin: 100px 0 0 0;
      }

      @include ipad-view {
        width: 100%;
        margin-top: 10px;
      }

      @include mobile-view {
        margin-top: 101px;
      }

      @include mobile-sm-view {
        max-width: 100%;
      }

      .better_to_travel {
        width: 100%;
        max-width: 594px;

        @include ipad-view {
        }

        .its_time {
          width: 100%;
          display: flex;

          .its_line {
            padding: 15px 0 0 0;

            .line {
              height: 5px;
              width: 50px;
              background: color(primary_theme);
              border-radius: 15px;

              @include ipad-view {
                width: 40px;
              }

              @include tab-view {
                width: 30px;
              }
            }
          }

          .its_text {
            padding: 0px 8px 0 8px;

            @include tab-view {
              padding: 0px 5px 0 5px;
            }

            p {
              @include small_text;
              @include font_400;
              color: color(primary_theme);

              @include ipad-view {
              }
            }
          }

          .its_img {
            @include ipad-view {
              margin: 0 0 0 -9px;
            }

            @include tab-view {
              margin: 0 0 0 -4px;
            }

            @include mobile-sm-view {
              margin: -7px 0 0px 0;
            }

            img {
              height: 45px;

              @include tab-max {
                height: 40px;
              }

              @include ipad-view {
                height: 35px;
              }

              @include tab-view {
                height: 30px;
              }

              @include mobile-view {
                height: 35px;
              }
            }
          }
        }

        .Manage_Bookings {
          width: 100%;
          padding: 29px 0 0 0;

          @include laptop_max {
            padding-top: 15px;
          }

          h1 {
            @include large_text;
            @include font_700;
          }

          p {
            @include small_text;
            @include font_400;
            color: color(black);
            padding: 20px 0 0 0;

            @include laptop_max {
              padding-top: 10px;
            }
          }
        }

        .Manage_Bookings_btn {
          padding: 14px 30px;
          border: 1px solid color(primary_theme_one);
          border-radius: 10px;
          background: color(primary_theme_one);
          color: color(white);
          font-size: 16px;
          font-family: $TypeFont_manrope;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          @include desktop_max {
            margin-top: 20px;
          }

          @include ipad-view {
            padding: 5px 20px;
            font-size: 14px;
          }

          @include mobile-view {
            font-size: 12px;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
              content: "";

              background: url(https://d1kaer0po85hkk.cloudfront.net/assets/arrow-right-circle.svg);
              background-size: contain;
              background-repeat: no-repeat;
              height: 20px;
              width: 20px;
              position: relative;
              margin: 0 0 0 13px;
              left: 0;
              transition: all ease 0.5s;

              @include ipad-view {
                height: 25px;
                width: 25px;
                margin: 0 0 0 8px;
                font-size: 12px;
              }

              @include mobile-view {
                margin: 0 0 0 5px;
                font-weight: 400px;
                font-family: $TypeFont_manrope;
              }

              @include mobile-sm-view {
                height: 20px;
                width: 20px;
                margin-left: 6px;
              }
            }
          }

          &:hover {
            background: color(border_clr);

            span {
              &::after {
                left: 10px;
              }
            }
          }
        }
      }
    }

    .banner_img {
      position: relative;
      padding-top: 50px;

      @include desktop_max {
        padding-top: 35px;
      }

      @include laptop_max {
        padding-left: 20px;
        padding-top: 31px;
      }

      @include tab-max {
        width: 50%;
      }

      @include ipad-view {
        margin: 35px auto;
      }

      @include mobile-view {
        margin: -82px auto;
        max-height: 350px;
        padding-top: 50px;
      }

      &::after {
        content: "";
        position: absolute;
        height: 275px;
        width: 100%;
        background: url(https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_right.png);
        background-image: -webkit-image-set(
          url("https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_right.webp")
        );
        background-image: image-set(
          url("https://d1kaer0po85hkk.cloudfront.net/assets/plan_icon_right.png")
        );
        background-repeat: no-repeat;
        background-size: contain;
        top: 63%;
        right: 0%;
        left: 100%;

        @include desktop_max {
          max-width: 215px;
          z-index: 1;
        }

        @include laptop_max {
          height: 210px;
          width: 100%;
          max-width: 223px;
        }

        @include tab-max {
          height: 120px;
          top: 69%;
          right: 0%;
          left: 81%;
        }

        @include ipad-view {
          height: 177px;
          top: 63%;
          left: 348px;
        }

        @include tab-view {
          height: 110px;
          top: 59%;
          left: 100%;
        }

        @include mobile-view {
          height: 101px;
          top: 61%;
          left: 98%;
        }

        @include mobile-sm-view {
          height: 76px;
          top: 61%;
          left: 100%;
        }
      }

      .img_banner {
        border-top-left-radius: 326px;
        border-top-right-radius: 326px;
        overflow: hidden;

        @include tab-max {
          border-top-left-radius: 226px;
        }

        @include ipad-view {
          border-top-left-radius: 320px;
        }

        img {
          max-width: 652px;
          max-height: 948px;
          width: 100%;

          @include desktop_max {
            height: calc(100vh - 130px);
          }

          @include laptop_max {
            max-width: 652px;
            max-height: 580px;
          }

          @include tab_max {
            max-width: 436px;
            max-height: 520px;
          }

          @include ipad-view {
            max-width: 330px;
            max-height: 480px;
          }

          @include tab-view {
            max-width: 350px;
            max-height: 350px;
          }

          @include mobile-view {
            max-width: 373px;
            max-height: 255px;
          }

          @include mobile-sm-view {
            max-width: 357px;
            max-height: 185px;
          }
        }
      }

      .banner_plan {
        position: absolute;
        bottom: 64%;
        right: -14%;

        @include desktop_max {
          bottom: 61%;
        }

        @include laptop_max {
          bottom: 60%;
          right: -11%;
        }

        @include tab-max {
          bottom: 59%;
          right: 10%;
        }

        @include ipad-view {
          bottom: 64%;
          right: -11%;
        }

        @include tab-view {
          bottom: 66%;
          right: -8%;
        }

        @include mobile-view {
          bottom: 55%;
          right: -8%;
        }

        @include mobile-sm-view {
          bottom: 51%;
          right: -13%;
        }

        img {
          max-height: 326px;
          max-width: 326px;

          @include desktop_max {
            max-height: 275px;
            max-width: 230px;
          }

          @include laptop_max {
            max-width: 200px;
          }

          @include tab-max {
            max-height: 195px;
            max-width: 180px;
          }

          @include ipad-view {
            max-height: 166px;
            max-width: 166px;
          }

          @include tab-view {
            max-height: 160px;
            max-width: 160px;
          }

          @include mobile-view {
            max-height: 95px;
            max-width: 95px;
          }

          @include mobile-sm-view {
            max-height: 75px;
            max-width: 75px;
          }
        }
      }
    }
  }
}

//  Close Banner Section CSS
.how_section {
  width: 100%;
  background-image: url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.webp");
  background-image: -webkit-image-set(
    url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.webp")
  );
  background-image: image-set(
    url("https://d1kaer0po85hkk.cloudfront.net/assets/Banner_bg_background.jpg")
  );
  margin: 80px 0 0 0;
  overflow: hidden;
  position: relative;
  background-size: contain;

  @include ipad-view {
    margin: 0px 0 0 0;
  }

  @include tab-view {
    margin: 40px 0 0 0;
  }

  @include mobile-view {
    margin: 0px 0 0 0;
  }

  &::before {
    content: "";
    position: absolute;
    height: 200px;
    width: 100%;
    background-image: url("https://d1kaer0po85hkk.cloudfront.net/assets/how_left_plan.webp");
    background-image: -webkit-image-set(
      url("https://d1kaer0po85hkk.cloudfront.net/assets/how_left_plan.webp")
    );
    background-image: (
      url("https://d1kaer0po85hkk.cloudfront.net/assets/how_left_plan.png")
    );
    background-repeat: no-repeat;
    background-size: contain;
    top: 0%;
    right: 0%;

    @include laptop_max {
      height: 200px;
      max-width: 350px;
      left: 0;
    }

    @include tab-max {
      height: 130px;
    }

    @include ipad-view {
      height: 85px;
    }

    @include mobile-view {
      left: 0%;
      top: -26%;
      height: 40px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    height: 280px;
    width: 100%;
    background-image: url(https://d1kaer0po85hkk.cloudfront.net/assets/how_right_plan.webp);
    background-image: -webkit-image-set(url(https://d1kaer0po85hkk.cloudfront.net/assets/how_right_plan.webp));
    background-image: url(https://d1kaer0po85hkk.cloudfront.net/assets/how_right_plan.png);
    background-repeat: no-repeat;
    background-size: contain;
    top: 30%;
    left: 84%;

    @include desktop_max {
      max-width: 200px;
      top: 35%;
      left: 87%;
    }

    @include laptop_max {
      height: 160px;
      max-width: 294px;
      top: 37%;
      left: 87%;
    }

    @include tab-max {
      display: none;
    }

    @include ipad-view {
      height: 85px;
      top: -16%;
      right: 6%;
    }

    @include mobile-view {
      left: 0%;
      top: -26%;
      height: 40px;
    }
  }

  .how_text {
    position: relative;

    .h_text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0 0 0;

      h1 {
        @include large_text;
        @include font_700;
      }
    }

    .how_line {
      width: 195px;
      border-radius: 15px;
      margin: 0 auto;
      height: 5px;
      background: color(primary_theme);

      @include ipad-view {
        height: 5px;
      }

      @include tab-view {
        width: 120px;
        height: 5px;
      }
    }

    .how_explore {
      max-width: 40%;
      margin: 10px auto;
      text-align: center;

      @include ipad-view {
        max-width: 55%;
      }

      @include tab-view {
        max-width: 50%;
      }

      @include mobile-view {
        max-width: 90%;
      }

      p {
        @include small_text;
        @include font_400;
      }
    }
  }

  .Choose_Airline_cover {
    margin: 51px 0 80px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include desktop_max {
      gap: 10px;
      justify-content: center;
    }

    @include ipad-view {
      margin: 30px 0 80px 0;
      flex-wrap: wrap;
    }

    @include mobile-sm-view {
      margin: 0px 0 0px 0;
    }

    .Choose_Airline {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 15px;
      padding: 30px 32px;
      box-shadow: 0 10px 10px -10px;

      &:hover {
        background: color(white);
        box-shadow: 0 0 5px #333;
      }

      @include desktop_max {
        width: 30%;
      }

      @include laptop_max {
        width: 27%;
        padding: 30px 15px;
      }

      @include tab-max {
        width: 33.3%;
        padding: 30px 20px;
      }

      @include ipad-view {
        padding: 25px 12px;
        width: 100%;
        margin: 15px 0 0 0;
      }

      @include mobile-view {
        width: 95%;
        margin: 5% auto;
      }

      .Choose_Airline_img {
        img {
          max-height: 80px;

          @include ipad-view {
            max-height: 70px;
          }

          @include mobile-view {
            max-height: 65px;
          }
        }
      }

      h1 {
        @include small_text;
        @include font_700;
        padding: 30px 0 10px 0;

        @include ipad-view {
          padding: 0;
        }
      }

      .Airline_text {
        width: 100%;
        max-width: 350px;

        p {
          @include medium_text;
          @include font_500;
          color: color(black);
        }
      }
    }
  }
}

.footer {
  width: 100%;
  background-color: color(whit_grid);

  .footer_top {
    width: 100%;
    padding: 70px 0 20px;

    @include ipad_view {
      padding: 60px 0 20px;
    }

    @include tab_view {
      padding: 40px 0 20px;
    }

    .ftr_flex {
      width: 100%;
      display: flex;

      @include ipad_view {
        flex-flow: column;
      }

      .footer_left {
        width: 35%;

        @include ipad_view {
          width: 100%;
          margin-bottom: 30px;
        }

        .footer_cover {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @include ipad-view {
            flex-wrap: wrap;
          }

          .ftr_block {
            width: 100%;

            @include ipad_view {
              text-align: center;
            }

            span {
              width: 100%;
              @include small_text;
              @include font_600;
              color: color(Contact_hed);
              margin: 8px 0;
              display: block;
            }

            a {
              @include detail_text;
              @include font_400;
              text-decoration: none;
              color: color(black);
              margin: 0 0 15px;
              display: flex;
              align-items: center;

              .email_icon {
                margin-right: 10px;
              }

              @include ipad_view {
                display: block;
              }
            }

            .socialmedia_icon {
              display: flex;
              padding: 0px;
              column-gap: 20px;

              @include ipad-view {
                padding: 20px 0 0 0px;
                justify-content: center;
              }

              li {
                width: auto;

                a {
                  padding: 5px;
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  z-index: 2;
                  margin: 0;
                  overflow: hidden;
                  background-color: transparent;
                  border: 1px solid color(bordercol);
                  transition: all 0.5s;
                  -webkit-transition: all 0.5s;
                  -moz-transition: all 0.5s;

                  @include mobile-view {
                    width: 45px;
                    height: 45px;
                  }

                  img {
                    &.normal_state {
                      display: block;
                    }

                    &.hover_state {
                      display: none;
                    }
                  }

                  &:hover {
                    animation: bounce 1s;
                    -webkit-animation: bounce 1s;
                    border: 0;

                    &.fb_icon {
                      background: linear-gradient(to left, #3b5998, #81a8fd);
                    }

                    &.ig_icon {
                      background: radial-gradient(
                        circle at 30% 107%,
                        #fdf497 0%,
                        #fdf497 5%,
                        #fd5949 45%,
                        #d6249f 60%,
                        #285aeb 90%
                      );
                    }

                    &.ld_icon {
                      background: linear-gradient(to left, #0e76a8, #47addc);
                    }

                    &.tw_icon {
                      background: linear-gradient(to left, #00acee, #4dccff);
                    }

                    img {
                      &.normal_state {
                        display: none;
                      }

                      &.hover_state {
                        display: block;
                      }
                    }
                  }
                }
              }
            }

            .text_btn {
              background-color: transparent;
              outline: none;
              border: 0;
              padding: 0;
              @include detail_text;
              @include font_400;
              text-decoration: none;
              color: color(black);
              margin: 0 0 15px;
              display: flex;
              align-items: center;
              box-shadow: none;

              @include ipad_view {
                margin: 0 auto 15px;
              }

              .email_icon {
                margin-right: 10px;
              }

              @include ipad_view {
                display: block;
              }

              &:hover {
                color: color(primary_theme);
              }
            }
          }
        }
      }

      .footer_right {
        width: 65%;
        display: flex;
        gap: 30px;

        @include ipad_view {
          width: 100%;
        }

        @include mobile_view {
          flex-flow: column;
        }

        .ftr_block {
          width: 33.33%;

          @include ipad_view {
            text-align: center;
          }

          @include mobile_view {
            width: 100%;

            &:last-child {
              margin-bottom: 10px;
            }
          }

          .top_title {
            width: 100%;
            @include small_text;
            @include font_500;
            color: color(black);
            margin-bottom: 8px;
          }

          ul {
            width: 100%;

            li {
              width: 100%;
              margin: 0 0 8px;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                @include detail_text;
                @include font_400;
                color: color(black);
                text-decoration: none;
                transition: all 0.5s;
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                display: inline-block;

                &:hover {
                  color: color(primary_theme);
                  transform: translateX(3px);
                  -webkit-transform: translateX(3px);
                  -moz-transform: translateX(3px);
                }
              }

              .btn-secondary {
                @include detail_text;
                @include font_400;
                color: color(black);
                text-decoration: none;
                background: none;
                border: none;
                padding: 0;
                transition: all 0.5s;
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;

                &:hover {
                  color: color(primary_theme);
                  transform: translateX(3px);
                  -webkit-transform: translateX(3px);
                  -moz-transform: translateX(3px);
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background: color(copy_right_bg);
    @include detail_text;
    color: color(black);
    text-align: center;

    p,
    small {
      text-align: center;
    }
  }
}

//FAQ section css

.FAQ_secition {
  width: 100%;
  padding-top: 0px;

  &.FAQ_home {
    padding: 80px 0 0;
    background: color(white);

    .accordion-item {
      &.accordion-active {
        background-color: color(white_light);
        border-color: color(primary_theme);
      }
    }
  }

  .accordion {
    padding-bottom: 15px;
  }

  .FAQ_text {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    @include sec_head;
    @include font_600;
    font-family: $TypeFont_raleway;
    position: relative;
    margin-bottom: 64px;

    @include ipad_view {
      margin-bottom: 44px;
    }

    @include tab-view {
      margin-bottom: 30px;
    }
  }

  .accordion-item {
    border-radius: 12px;
    overflow: hidden;
    border: none;
    background-color: transparent;
    margin-bottom: 10px;
    border: 1px solid color(bordercol);
    border-radius: 8px;
    transition: box-shadow 0.5s;
    -webkit-transition: box-shadow 0.5s;
    -moz-transition: box-shadow 0.5s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    }

    &.accordion-active {
      background-color: color(white_light);
      border-color: color(header_bg);

      .accordion-button {
        &::after {
          transform: rotate(0deg);
        }
      }
    }

    .accordion-button {
      @include small_text;
      @include font_600;
      font-family: $TypeFont_manrope;
      background-color: transparent;
      box-shadow: none;
      color: color(text_clr);
      padding: 20px;

      @include tab_view {
        padding: 16px;
      }

      &::after {
        width: 35px;
        height: 35px;
        background-image: url(https://d1kaer0po85hkk.cloudfront.net/assets/acc_btn.svg);
        background-size: cover;
        transform: rotate(180deg);

        @include tab_view {
          width: 20px;
          height: 20px;
        }
      }
    }

    .accordion-body {
      @include detail_text;
      @include font_400;
      padding: 0 20px 20px;

      a {
        color: color(primary_theme);

        &:hover {
          text-decoration: underline !important;
        }
      }

      @include mobile_view {
        word-break: break-all;
      }
    }
  }
}

//People Also Search For
.tabContent_icons_grid {
  margin: 32px 0 0;

  .col_items:nth-child(n + 5) {
    @media (min-width: 992px) {
      margin-top: 24px;
    }
  }

  a {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .tabContentIcons_list {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid color(header_bg);
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    @include ipad_view {
      margin-bottom: 24px;
      height: calc(100% - 24px);
    }

    @include tab_view {
      margin: 0 0 15px;
      height: calc(100% - 15px);
    }

    &:hover {
      box-shadow: 0px 1px 25px rgba($primary_theme_opacity, $btn_opacity_25);
    }

    .IconsList_block {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-flow: column;
      padding: 16px 5px;

      @include ipad_view {
        flex-flow: row;
        padding: 10px;
      }

      .IconsList_icon {
        background: rgba($primary_theme_opacity, $btn_opacity_7);
        height: 54px;
        width: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: color(header_bg);
        margin: 0 0 8px;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;

        @include ipad_view {
          margin: 0 10px 0 0;
        }

        @include tab_view {
          width: 36px;
          height: 36px;
        }

        svg {
          height: 24px;
          width: 24px;
          position: relative;
          z-index: 1;

          @include tab_view {
            width: 18px;
            height: 18px;
          }
        }

        img {
          height: 24px;
          width: 24px;

          @include tab_view {
            width: 18px;
            height: 18px;
          }
        }
      }

      .IconsList_text {
        @include detail_text;
        @include font_400;
        font-family: $TypeFont_manrope;
        color: color(header_bg);
        display: flex;
        text-align: center;
        align-items: center;

        @include tab-view {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  .col_items {
    @include mobile-view {
      width: 100%;
    }
  }
}

//-------------------------------------------------------------- secodepage-------------------------------------------------------------------------
input[type="tel"]::-moz-placeholder {
  color: black;
  opacity: 1;
}

// input[type="text"]::-moz-placeholder { color:color(black); opacity: 1; }

.custom_phoneInput {
  border: 1px solid color(Contact_bor);
  border-radius: 8px;
  padding: 0 20px;

  .PhoneInputInput {
    border: 0 !important;
    padding-left: 5px !important;
    padding-right: 0 !important;
  }

  .PhoneInputCountry {
    .PhoneInputCountrySelectArrow {
      position: relative;
      top: -1px;
      right: -1px;
    }
  }
}

.common_btn {
  @include common_btn;
}

.form_group {
  margin: 0 0 24px;

  .form-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: color(black);
    margin: 0 0 8px;
    display: block;
    width: 100%;
  }

  .form-control {
    width: 100%;
    height: 55px;
    background: transparent;
    border: 1px solid color(dark_gray);
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: color(black);
    background-color: color(form_input_bg);
    padding: 0 18px;
    border-radius: 8px;
    transition: box-shadow 0.5s ease, border 0.5s ease;
    -webkit-transition: box-shadow 0.5s ease, border 0.5s ease;
    -moz-transition: box-shadow 0.5s ease, border 0.5s ease;

    ::-webkit-input-placeholder {
      color: color(dark_gray);
    }

    :-moz-placeholder {
      color: color(dark_gray);
    }

    ::-moz-placeholder {
      color: color(dark_gray);
    }

    :-ms-input-placeholder {
      color: color(dark_gray);
    }

    ::-ms-input-placeholder {
      color: color(dark_gray);
    }

    ::placeholder {
      color: color(dark_gray);
    }

    &:hover {
      border-color: color(black);
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      border-color: color(black);
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2) !important;
    }
  }
}

html,
body * {
  scrollbar-color: color(primary_theme)
    rgba($primary_theme_opacity, $btn_opacity_7) !important;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  background-color: rgba($primary_theme_opacity, $btn_opacity_7);
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: rgba($primary_theme_opacity, $btn_opacity_7);
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
  background-color: color(primary_theme);
}

.rdt_Table {
  border: 1px solid rgba($primary_theme_opacity);
  background: rgba($primary_theme_opacity, $btn_opacity_7);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 6px;

  .rdt_TableHeadRow {
    @include detail_text;
    @include font_600;
    color: color(Contact_hed);
    border-color: rgba($primary_theme_opacity);
    background: rgba($primary_theme_opacity, $btn_opacity_7);

    .sc-kFCsca {
      @include font_12;
      padding: 0 5px;
      position: relative;
      top: 2px;

      &.hgDOOo {
        top: 0;
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      @include detail_text;
      color: color(dark_gray);
      border-color: rgba($primary_theme_opacity);
    }
  }
}

.customTable_style {
  .rdt_Table {
    border: 1px solid rgba($primary_theme_opacity);
    background: transparent;
    border-radius: 0;
    overflow: hidden;
    border: 0;
    border-color: transparent;

    .rdt_TableHead {
      margin: 0 0 18px;

      .rdt_TableHeadRow {
        @include small_text;
        @include font_600;
        color: color(black);
        border-color: transparent;
        background: color(white);
        border-radius: 5px;
        border: 1px solid transparent;
        transition: border 0.5s ease;
        -webkit-transition: border 0.5s ease;
        -moz-transition: border 0.5s ease;

        &:hover {
          border-color: color(primary_theme);
        }

        .rdt_TableCol {
          padding: 20px 15px;
        }
      }
    }

    .rdt_TableBody {
      .rdt_TableRow {
        @include medium_text;
        color: color(black);
        border: 0;
        border-color: transparent;
        margin: 0 0 18px;
        border-radius: 5px;
        border: 1px solid transparent;
        transition: border 0.5s ease;
        -webkit-transition: border 0.5s ease;
        -moz-transition: border 0.5s ease;

        &:hover {
          border-color: color(primary_theme);
        }

        .rdt_TableCell {
          padding-top: 10px;
          padding-bottom: 10px;

          .table_logo {
            max-height: 70px;
            width: auto;
          }

          a {
            color: color(primary_theme);
            position: relative;

            &:after {
              content: "";
              position: absolute;
              top: auto;
              bottom: -2px;
              left: 0;
              right: 0;
              width: 100%;
              height: 2px;
              background: color(primary_theme);
              transition: all 0.5s ease-in-out;
              -webkit-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              transform: scale(0, 1);
              -webkit-transform: scale(0, 1);
              -moz-transform: scale(0, 1);
            }

            &:hover {
              &:after {
                transform: scale(1, 1);
                -webkit-transform: scale(1, 1);
                -moz-transform: scale(1, 1);
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

body {
  > .skiptranslate {
    display: none !important;
  }

  > .VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
    display: none !important;
  }
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  left: -17px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: -17px !important;
}

.btn-close {
  outline: none !important;
  box-shadow: none !important;
}

.checkbox_item {
  .checkbox_group {
    position: relative;
    padding: 0 0 0 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include tab_view {
      padding: 0 0 0 28px;
    }

    .checkbox_label {
      cursor: pointer;
      @include medium_text;
      line-height: normal;
    }

    .checkobx_input {
      position: absolute;
      opacity: 0;
      z-index: 2;
      cursor: pointer;

      &:checked ~ .checkmark {
        background-color: color(primary_theme);
      }

      &:checked ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      height: 22px;
      width: 22px;
      background-color: color(white);
      border: 2px solid color(primary_theme);
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 7px;
        height: 13px;
        border: solid color(white);
        margin: -2px auto 0;
        text-align: center;
        border-width: 0 3px 3px 0;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        -ms-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
      }
    }
  }
}

.commonModal {
  .modal-header {
    .modal-title {
      @include font_600;
    }
  }

  .commonModalClose {
    position: absolute;
    top: 16px;
    right: 16px;
    left: auto;
    bottom: auto;
    height: 34px;
    width: 34px;
    z-index: 1;
    text-indent: -9999px;
    color: transparent;
    overflow: hidden;
    background: transparent
      url("https://d1kaer0po85hkk.cloudfront.net/assets/ic_closePopup_black.svg")
      no-repeat center;
    background-size: 20px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: 0;

    &.white_ic {
      background: transparent
        url("https://d1kaer0po85hkk.cloudfront.net/assets/ic_closePopup_white.svg")
        no-repeat center;
      background-size: 20px;
    }

    @include ipad-view {
      height: 28px;
      width: 28px;
      background-size: 16px;
    }
  }
}

.progress {
  background-color: color(gray);
  border-radius: 30px;

  .progress-bar {
    background-color: color(primary_theme);
    border-radius: 30px;
  }

  &.progress_small {
    height: 8px;
  }
}

.content_loader {
  text-align: center;
  margin: 0 auto;
  display: block;
  clear: both;
  width: 100%;
  margin: 10px 0;

  &.blogList {
    min-height: 1000px;
  }
}

.searchBar_fixed {
  background-color: transparent;
  z-index: 9;
  top: 8px !important;

  #searchbar {
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.15) !important;
  }

  .search_bar_wrapper {
    @include tab-max {
      max-width: 420px !important;
    }

    @include ipad-view {
      max-width: 100% !important;
    }
  }

  @include ipad_view {
    top: 75px !important;
  }

  @include tab_view {
    top: 56px !important;
  }

  @include mobile_view {
    top: 50px !important;
  }

  .search_list {
    margin-top: 0 !important;
  }
}

.sec_subscribe {
  &.subscribe_home {
    background-color: color(white);
  }
}

.form-control.is-valid {
  background-image: url("https://oneclick-pnr.s3.amazonaws.com/icon-form-valid.svg");
  background-repeat: no-repeat;
  background-size: 25px;
}

.was-validated .form-control:valid {
  background-image: url("https://oneclick-pnr.s3.amazonaws.com/icon-form-valid.svg");
  background-repeat: no-repeat;
  background-size: 25px;
}

.searchBar_error {
  display: block;
  width: 100%;

  h2 {
    @include font_20;
    line-height: normal;
    text-align: center;
  }
}

.invalid-feedback {
  @include font-14;
}

.page_404_main {
  .wraper {
    header {
      background-color: color(Contact_btn);
    }
  }
}

.page_unsubscribed_main {
  background-color: color(white) !important;
}

.populerRoutesOuter {
  text-align: center;

  .nav-tabs {
    border-radius: 8px;
    border: 1px solid var(--Light-grey-2, #d8dadd);
    background: #fff;
    padding: 4px;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 33px;

    .nav-item {
      .nav-link {
        cursor: pointer;
        border: none;
        color: color(dark_gray);
        font-family: $TypeFont_manrope;
        font-weight: 400;
        line-height: 1.5;
        @include medium_text;

        @include mobile-view {
          @include font_12;
          padding: 5px 9px;
        }

        &.active {
          border-radius: 4px;
          color: #fff;
          background: var(--header-bg);
        }
      }
    }
  }
}

// -----------------new---------
.react-datepicker__day--selected {
  background-color: color(Contact_btn) !important;
  outline: 3px solid color(secondary);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__day {
  width: 36px !important;
  height: 36px;
}

.react-datepicker__day--in-selecting-range {
  border: none !important;
  background-color: transparent !important;
  color: color(black) !important;
}

.react-datepicker__day--in-range {
  border: 1px solid currentColor !important;
  background-color: transparent !important;
  color: color(Contact_btn) !important;
}

.react-datepicker__day--range-end {
  border: 1px solid color(Contact_btn) !important;
  color: color(whit_grid) !important;
}

.react-datepicker__day--outside-month {
  border: none;
}

.react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--previous--disabled {
  display: none;
}

.react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--next {
  background: transparent
    url(https://d1kaer0po85hkk.cloudfront.net/assets/cal_arrow_right.svg)
    no-repeat 50% !important;
  left: auto;
  right: 30px;
  top: 20px;

  @include tab_view {
    top: 20px;
  }

  @include mobile_view {
    top: 5px;
    right: 5px;
  }
}

.react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  background: transparent
    url(https://d1kaer0po85hkk.cloudfront.net/assets/cal_arrow_right.svg)
    no-repeat 50% !important;
  left: 30px;
  right: auto;
  transform: rotate(180deg);
  top: 20px;

  @include tab_view {
    top: 20px;
  }

  @include mobile_view {
    top: 5px;
    left: 5px;
  }

  .react-datepicker__navigation-icon--previous {
    &::before {
      content: none;
    }
  }
}

.react-datepicker__header .react-datepicker__current-month {
  color: color(dateHeader);
  @include font_16;
  @include font_700;
  margin-bottom: 10px;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding: 24px 24px 0 !important;
}

.react-datepicker .react-datepicker__month {
  padding: 4px 24px 24px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__day--selected {
  background-color: #4600ff !important;
  outline: 3px solid #f4ebff;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--today {
  position: relative;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--today {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent
      url(https://d1kaer0po85hkk.cloudfront.net/assets/today-badge.svg)
      no-repeat 100% 0 !important;
    background-size: 12px !important;
  }
}

.react-datepicker__day .custom-day .dateWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 4px;

  .dayCounter {
    position: absolute;
    top: -20px;
    border-radius: 10px;
    background-color: color(Contact_btn);
    color: color(white);
    padding: 7px 3px;
    line-height: 0;
    width: 100%;
    @include font_8;
    display: none;

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: color(Contact_btn);
      border-width: 5px;
      margin-left: -5px;
    }
  }

  .price {
    @include font_8;
    color: color(calender_font);
    line-height: 0;
    position: relative;
    bottom: 1px;
  }
}

.react-datepicker__day.custom-day.react-datepicker__day--selected.react-datepicker__day--range-end.react-datepicker__day--in-range,
.react-datepicker__day.custom-day.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range {
  color: color(white) !important;
}

.react-datepicker__day.custom-day.react-datepicker__day--range-end.react-datepicker__day--in-range {
  color: color(Contact_btn) !important;
}

span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
  &::before {
    display: none;
  }
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.datePickerWidgetOuter {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      .react-datepicker__calendar-icon {
        pointer-events: none;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }

      .widget_real_input_outer {
        cursor: pointer;
        border: none !important;
        padding: 0 10px 0 17px !important;
      }
    }
  }

  .react-datepicker__tab-loop {
    @include mobile-view {
      .react-datepicker-popper {
        .react-datepicker {
          .react-datepicker__month-container {
            .react-datepicker__header {
              padding: 9px 0 0 !important;
            }

            .react-datepicker__month {
              padding: 9px 0 0 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.subHeading {
  margin-top: 16px;
  font-weight: 700;
  @include sub_head_text;
}

.listing_main_loader_images_outer {
  .dotlottie-container {
    max-width: 1000px;
    width: 100% !important;

    @include mobile_view {
      height: 115px !important;
    }
  }
}

.faqWrapperOuter {
  .FAQ_secition.FAQ_home {
    padding: 0;

    .container {
      padding: 0;

      .FAQ_text {
        text-align: start;
        margin-bottom: 30px;
        justify-content: flex-start;
        color: color(black_dark);
        font-feature-settings: "liga" off;
        line-height: 42px;
        @include font_28;
        @include font_700;
        font-family: $TypeFont_manrope;

        @include ipad_view {
          @include font_22;
          margin-bottom: 14px;
        }
      }
    }
  }
}

.common_btn_iconsOnly {
  width: 40px !important;
  height: 40px;
  border-radius: 10px;
}
