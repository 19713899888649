$desktop_max: 1920px;
$laptop_max: 1440px;
$laptop_small: 1399px;
$tab_vertical: 1024px;
$tab_max: 1199px;
$ipad_view: 991px;
$tab_view: 767px;
$mobile_view: 558px;
$mobile_sm_view: 350px;

@mixin desktop_max {
  @media (max-width: #{$desktop_max}) {
    @content;
  }
}

@mixin laptop_max {
  @media (max-width: #{$laptop_max}) {
    @content;
  }
}

@mixin tab-max {
  @media (max-width: #{$tab_max}) {
    @content;
  }
}

@mixin tab_vertical {
  @media (max-width: #{$tab_vertical}) {
    @content;
  }
}

@mixin ipad-view {
  @media (max-width: #{$ipad_view}) {
    @content;
  }
}

@mixin tab-view {
  @media (max-width: #{$tab_view}) {
    @content;
  }
}

@mixin mobile-view {
  @media (max-width: #{$mobile_view}) {
    @content;
  }
}

@mixin mobile-sm-view {
  @media (max-width: #{$mobile_sm_view}) {
    @content;
  }
}

@mixin laptop_small {
  @media (max-width: #{$laptop_small}) {
    @content;
  }
}
