@forward "guidlineColor";
@forward "guidlineMedia";

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Black.eot');
  src: url('./raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Black.woff2') format('woff2'),
    url('./raleway/Raleway-Black.woff') format('woff'),
    url('./raleway/Raleway-Black.ttf') format('truetype'),
    url('./raleway/Raleway-Black.svg#Raleway-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Bold.eot');
  src: url('./raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Bold.woff2') format('woff2'),
    url('./raleway/Raleway-Bold.woff') format('woff'),
    url('./raleway/Raleway-Bold.ttf') format('truetype'),
    url('./raleway/Raleway-Bold.svg#Raleway-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-ExtraBold.eot');
  src: url('./raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-ExtraBold.woff2') format('woff2'),
    url('./raleway/Raleway-ExtraBold.woff') format('woff'),
    url('./raleway/Raleway-ExtraBold.ttf') format('truetype'),
    url('./raleway/Raleway-ExtraBold.svg#Raleway-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-ExtraLight.eot');
  src: url('./raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-ExtraLight.woff2') format('woff2'),
    url('./raleway/Raleway-ExtraLight.woff') format('woff'),
    url('./raleway/Raleway-ExtraLight.ttf') format('truetype'),
    url('./raleway/Raleway-ExtraLight.svg#Raleway-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Light.eot');
  src: url('./raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Light.woff2') format('woff2'),
    url('./raleway/Raleway-Light.woff') format('woff'),
    url('./raleway/Raleway-Light.ttf') format('truetype'),
    url('./raleway/Raleway-Light.svg#Raleway-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Medium.eot');
  src: url('./raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Medium.woff2') format('woff2'),
    url('./raleway/Raleway-Medium.woff') format('woff'),
    url('./raleway/Raleway-Medium.ttf') format('truetype'),
    url('./raleway/Raleway-Medium.svg#Raleway-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Regular.eot');
  src: url('./raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Regular.woff2') format('woff2'),
    url('./raleway/Raleway-Regular.woff') format('woff'),
    url('./raleway/Raleway-Regular.ttf') format('truetype'),
    url('./raleway/Raleway-Regular.svg#Raleway-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-SemiBold.eot');
  src: url('./raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-SemiBold.woff2') format('woff2'),
    url('./raleway/Raleway-SemiBold.woff') format('woff'),
    url('./raleway/Raleway-SemiBold.ttf') format('truetype'),
    url('./raleway/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('./raleway/Raleway-Thin.eot');
  src: url('./raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
    url('./raleway/Raleway-Thin.woff2') format('woff2'),
    url('./raleway/Raleway-Thin.woff') format('woff'),
    url('./raleway/Raleway-Thin.ttf') format('truetype'),
    url('./raleway/Raleway-Thin.svg#Raleway-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-ExtraBold.eot');
  src: url('./manrope/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-ExtraBold.woff2') format('woff2'),
    url('./manrope/Manrope-ExtraBold.woff') format('woff'),
    url('./manrope/Manrope-ExtraBold.ttf') format('truetype'),
    url('./manrope/Manrope-ExtraBold.svg#Manrope-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-ExtraLight.eot');
  src: url('./manrope/Manrope-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-ExtraLight.woff2') format('woff2'),
    url('./manrope/Manrope-ExtraLight.woff') format('woff'),
    url('./manrope/Manrope-ExtraLight.ttf') format('truetype'),
    url('./manrope/Manrope-ExtraLight.svg#Manrope-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Regular.eot');
  src: url('./manrope/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-Regular.woff2') format('woff2'),
    url('./manrope/Manrope-Regular.woff') format('woff'),
    url('./manrope/Manrope-Regular.ttf') format('truetype'),
    url('./manrope/Manrope-Regular.svg#Manrope-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Light.eot');
  src: url('./manrope/Manrope-Light.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-Light.woff2') format('woff2'),
    url('./manrope/Manrope-Light.woff') format('woff'),
    url('./manrope/Manrope-Light.ttf') format('truetype'),
    url('./manrope/Manrope-Light.svg#Manrope-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Bold.eot');
  src: url('./manrope/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-Bold.woff2') format('woff2'),
    url('./manrope/Manrope-Bold.woff') format('woff'),
    url('./manrope/Manrope-Bold.ttf') format('truetype'),
    url('./manrope/Manrope-Bold.svg#Manrope-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-Medium.eot');
  src: url('./manrope/Manrope-Medium.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-Medium.woff2') format('woff2'),
    url('./manrope/Manrope-Medium.woff') format('woff'),
    url('./manrope/Manrope-Medium.ttf') format('truetype'),
    url('./manrope/Manrope-Medium.svg#Manrope-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./manrope/Manrope-SemiBold.eot');
  src: url('./manrope/Manrope-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./manrope/Manrope-SemiBold.woff2') format('woff2'),
    url('./manrope/Manrope-SemiBold.woff') format('woff'),
    url('./manrope/Manrope-SemiBold.ttf') format('truetype'),
    url('./manrope/Manrope-SemiBold.svg#Manrope-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



// Typeface
$TypeFont1: "Poppins", sans-serif;
$TypeFont3: "Syne", sans-serif;
$TypeFont_manrope: 'Manrope';
$TypeFont_raleway: 'Raleway';

/* ======= */
@mixin font_700 {
  font-weight: 700;
}

@mixin font_600 {
  font-weight: 600;
}

@mixin font_500 {
  font-weight: 500;
}

@mixin font_400 {
  font-weight: 400;
}

@mixin font_300 {
  font-weight: 300;
}

@mixin font_8 {
  font-size: 10px;
}

@mixin font_10 {
  font-size: 10px;
}

@mixin font_12 {
  font-size: 12px;
}

@mixin font_13 {
  font-size: 13px;
}

@mixin font_14 {
  font-size: 14px;
}

@mixin font_16 {
  font-size: 16px;
}

@mixin font_18 {
  font-size: 18px;
}

@mixin font_20 {
  font-size: 20px;
}

@mixin font_22 {
  font-size: 22px;
}
@mixin font_24 {
  font-size: 24px;
}
@mixin font_28 {
  font-size: 28px;
}
@mixin font_34 {
  font-size: 34px; 
}
@mixin font_44 {
  font-size: 44px; 
}
@mixin font_54 {
  font-size: 54px; 
}
@mixin font_64 {
  font-size: 64px; 
}

@mixin font_68 {
  font-size: 68px; 
}

@mixin large_text {
  // font-size: 72px;
  // line-height: 108px;
  font-size: 58px;
  line-height: 94px;

  @include tab_max {
    font-size: 56px;
    line-height: 68px;
  }

  @include ipad_view {
    font-size: 48px;
    line-height: 56px;
  }

  @include tab_view {
    font-size: 36px;
    line-height: 44px;
  }

  @include mobile_view {
    font-size: 24px;
    line-height: 36px;
  }
}

@mixin sec_head {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;

  @include ipad_view {
    font-size: 40px;
    line-height: 52px;
  }

  @include tab_view {
    font-size: 30px;
    line-height: 42px;
  }

  @include mobile_view {
    font-size: 22px;
    line-height: 34px;
  }
}
@mixin modal_head {
  font-size: 32px;
  line-height: 50px;

  @include ipad_view {
    font-size: 28px;
    line-height: 46px;
  }
  @include tab_view {
    font-size: 24px;
    line-height: 42px;
  }
  @include mobile_view {
    font-size: 22px;
    line-height: 40px;
  }
}
@mixin heading_2 {
  font-size: 28px;
  line-height: 40px;
  font-family: $TypeFont_raleway;

  @include ipad_view {
    font-size: 20px;
    line-height: 32px;
  }

  @include tab_view {
    font-size: 18px;
    line-height: 22px;
  }
}
@mixin point_head{
  font-size: 36px;
  line-height: 54px;

  @include ipad_view {
    font-size: 30px;
    line-height: 46px;
  }
  @include tab_view {
    font-size: 24px;
    line-height: 38px;
  }
}
@mixin largeText {
  font-size: 24px;
  line-height: 32px;

  @include tab-max {
    font-size: 20px;
    line-height: 24px;
  }

  @include ipad-view {
    font-size: 15px;
    line-height: 24px;
  }
}

@mixin all_contact_list {
  font-size: 22px;
  line-height: 32px;
  font-family: $TypeFont_manrope;
  @include font_400;

  @include ipad_view {
    font-size: 18px;
    line-height: 28px;
  }
}

@mixin extraSmall_text {
  font-size: 12px;
  line-height: 18px;
}

@mixin small_text {
  font-size: 20px;
  line-height: 31px;

  @include ipad_view {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin medium_text {
  font-size: 18px;
  line-height: 27px;

  @include ipad_view {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin detail_text {
  font-size: 16px;
  line-height: 24px;

  @include ipad_view {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin sub_head_text {
  font-size: 16px;
  line-height: 24px;

  @include ipad_view {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin common_btn {
  padding: 15px 40px;
  @include font_600;
  font-size: 20px;
  line-height: normal;
  border-radius: 44px;
  background-color: color(header_bg);
  color: color(white);
  border: 1px solid color(header_bg);
  text-align: center;
  margin: 0;
  //text-transform: capitalize;

  @include ipad_view {
    font-size: 16px;
    padding: 15px 40px;
  }

  @include tab_view {
    padding: 12px 30px;
  }

  &.withArrow {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .btn_arrow_ic {
        width: 24px;
        right: -8px;

        @include tab_view {
          right: -4px;
        }
      }
    }

    .btn_arrow_ic {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 0;
      right: 0;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &:disabled {
    background-color: color(disabled) !important;
    color: color(white) !important;
    cursor: default;
  }
  &.btn_upArrow{
    font-size: 18px;
    @include font-600;
    border-radius: 8px;
    color: color(Contact_btn);
    background-color: color(white);
    padding: 18px 23px;
    border: 0;
    box-shadow: 0px 10px 30px rgba(color(shadow_color), $btn_opacity_2); 
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;

    .btn_arrow_ic{
      margin: 0 0 0 12px;
      display: inline-block;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
    }
    &:hover{
      box-shadow: 2px 2px 30px rgba(color(shadow_color), $btn_opacity_5);

      .btn_arrow_ic{
        transform: rotate(45deg);
      }
    }
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0, 0, 0);
  }

  40% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes rotate {
  0% {
    transform: scale(0.92) rotate(0deg)
  }

  100% {
    transform: scale(0.92) rotate(360deg)
  }
}

.modal_subscribe {
  .modal-dialog {
    max-width: 610px;
  }
}