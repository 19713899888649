.root:global {
  --primary-color: rgb(68, 0, 255);
  --site-text-color: rgb(41, 41, 41);
  --content-text-color: rgb(107, 54, 247);
  --header-bg: rgb(255, 255, 255);
  --header-text: rgb(0, 0, 0);
  --hamburger-line: rgb(0, 0, 0);

  --primary-color-rgb: 68, 0, 255;
}

$colors: (
  primary_theme: var(--primary-color),
  primary_theme_opacity: var(--primary-color-rgb),
  primary_theme_one: var(--content-text-color),
  border_clr: var(--primary-color),
  header_bg: var(--header-bg),
  header_text: var(--header-text),
  text_clr: var(--site-text-color),
  hamburger_line: var(--hamburger-line),
  svg_icon: var(--primary-color),
  primary_opacity: #d6c8fb,
  bg_clr_one: #eee8ff,
  content: #848484,
  bordercol: #c9c9c9,
  white: #fff,
  neutrals_text_clr4a: #4a4a4a,
  neutrals_white_fill75: rgba(255, 255, 255, 0.75),
  gery_line: #ccc,
  gridb_colr: #4c07fe,
  gridr_colr: #ef5151,
  whit_grid: #ffffff,
  border_gray: #e7e7e7,
  Contact_btn: #4600ff,
  Contact_tex: #667085,
  Contact_hed: #101828,
  Contact_men: #848484,
  text_Contact: #344054,
  Contact_bor: #d0d5dd,
  border_color: #e2e2e2,
  red_dark: #dc3545,
  red_light: #fff2f5,
  red_dark_2: #c5012d,
  red_dark_3: #e32323,
  gray_color: #969696,
  success_bg: #00ba35,
  error_bg: #e32323,
  copy_right_bg: #f9fafb,
  white_light: #fafafa,
  tab_color: #eaeaea,
  shadow_color: #2c08ff,
  congratulation_head: #ff6b00,

  // ======================================
  dark_indigo: #7e4dff,
  indigo: #a280ff,
  light_indigo: #e1d6ff,
  secondary: #f4ebff,

  black: #000,
  black_dark: #111111,
  black_dark2525: #252525,
  dark_gray: #858c94,
  gray: #d8dadd,
  light_gray: #f3f4f5,
  v_light_gray: #fafafa,
  form_input_bg: #fbfbfb,
  disabled: #c3c3c3,

  dateHeader: #52575c,
);
$primary_theme_opacity: var(--primary-color-rgb);
$btn_opacity_7: 0.15;
$btn_opacity_35: 0.35;
$btn_opacity_2: 0.2;
$btn_opacity_25: 0.25;
$btn_opacity_5: 0.5;

@function color($color_name) {
  @return map-get($colors, $color_name);
}
